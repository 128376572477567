
.filterBtnContainer{
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 15px;
  }
  
  .button {
    
      color: #fff;
      background-color: hwb(216 5% 1%);
      border-color: #0d6efd;
      outline: none;
      border-radius: 6px;
      padding: 5px 0px;
  }
  .button:hover{
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    outline: none;
    border-radius: 6px;
    padding: 5px 0px;
  }
  
  .inputDivs{
    text-align: left;
    display: flex;
    justify-content: space-between;
  
  }
  select{
    width: 150px;
    padding: 5px 0px;
  }
  input[type="date"]{
    width: 150px;
    padding: 5px 0px;
  }
  
  .logScreenTop{
    background: #A8DEF6;
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 30px;  
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sinoutFont{
      font-weight: 600;
      display: flex;
      align-items: center;
      
  
    }
    .signoutIcon{
      padding-left: 7px;
      padding-bottom: 15px;
      &:hover{
        cursor: pointer;
      }
    }  
  }
  .SignOut{
    display: flex;
    flex-direction: column;
  }
  
  
  .logScreenTop{
    padding: 10px;
    text-align: right;
  }
  
  .newsContainer{
    width: auto;
    height: auto;
  }
  .adlistingModule{
    margin-top: 30px;
  }
  
  .regionSelection{
    .css-1nmdiq5-menu{
      z-index: 3; 
    }
  
  }