.modBody{
    .parentModalContentJsx{
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
            display: inline-block;
            font-size: large;
            font-weight: bold;
        }
        .success{
            color: green;
        }
        .failure{
            color: red;
        }
    }
    .btnContent{
        display: flex;
        align-items: center;
       
      button{
        background-color: #0b5ed7;
        border: none;
        &:hover{
            background-color:#05357e;
            border: none;
        }
      }  
    }
}