.newsSlider {
  .slick-list{
    padding: 30px;
  }
  .thumbOuter {
    .thumbnail {
      box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
      height: 200px;
      &:hover {
        cursor: pointer;
      }
    }
    span {
      margin-left: 30%;
      text-align: center;
      font-size: smaller;
      font-weight: 500;
    }
    .selectedThumb {
      padding: 0px 1px 0px 0px;
      outline: 1px solid #78b7d3;
      border-bottom: 2px solid #78b7d3;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
        rgba(0, 0, 0, 0.05) 0px 5px 10px;
    }
    .dragsPosn {
      position: absolute;
      // position: relative;
      top: 23px;
    }
    .dragsCont {
      // position: absolute;
      position: relative;
      top: 0px;
    }
    .contentPosn {
      position: absolute;
      // position: relative;
      // top: 23px;
    }
  }

  .slick-slider {
    // width: 800px;

    .slick-prev:before,
    .slick-next:before {
      color: black;
    }
  }

  .thumb_templates {
    .templateDesign {
      position: relative;
      img {
        width: 100%;
      }
      .pageNoLocation {
        display: flex;
        justify-content: space-between;
        padding: 1px;
        position: absolute;
        top: 0;
        width: 100%;
        font-size: 4px;
        color: rgb(103, 98, 98);
      }
    }
    .scrShotImg {
      width: -webkit-fill-available;
    }

    .redLine {
      height: 5px;
      background-color: red;
    }
    .template1 {
      .innerCont {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .eL,
        .eR {
          height: 15px;
          width: 35px;
          background-color: rgba(5, 138, 220, 0.3098039216);
          border: 2px solid rgb(96, 163, 251);
        }
        img {
          height: 15px;
        }
        padding-bottom: 3px;
      }
    }
    .template2 {
      img {
        height: 25px;
        width: 50%;
      }
      .redLine {
        width: 50%;
        margin-top: 3px;
      }
    }
    .template3 {
      direction: rtl;
      img {
        height: 25px;
        width: 50%;
      }
      .redLine {
        width: 50%;
        margin-top: 3px;
      }
    }
    .others {
      .noTemplateDesign {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        .locality {
          font-size: 5px;
          display: flex;
        }
        .imgDiv {
          padding-right: 25px;
          img {
            height: 6px;
          }
        }
        .pgN {
          font-size: 5px;
        }
      }
    }
  }
}
