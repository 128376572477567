.formContainer {
    padding: 20px;
    background: #fff;
    Button {
        width: 100%;
        padding: 10px;
        font-weight: 600;
    }
    Form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding: 20px;
        input {
            padding: 10px;
        }
    }
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}