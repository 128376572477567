.tableList{
   max-height: 250px;
   overflow: auto;
   margin-bottom: 30px;
   border: 2px solid grey;
   background-color: white;
   text-align: center;
   table  { 
    .postPoned{
        background-color: #eb009178;
        font-weight: 400;
        color: white;
    }
    .isReserved{
        background-color: #10619bdb;
        font-weight: 400;
        color: white;
    }
    
    border-collapse: collapse; 
    width: 100%; 
        .noRecordsTodayTable{
            margin: 0 auto;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            padding: 10px;
        }

    }
    thead  { 
    
        th{
           border: 1px solid grey;
           background-color: whitesmoke; 
           position: sticky; top: 0; z-index: 1; 
           padding: 10px;
        }
    }
    tr{
        .adTypeTd{
            max-width: 200px;
        }
        
        td{
            border: 1px solid grey;
            padding: 10px;
            .actionBtn{
                display: flex;
                // flex-direction: column;
                justify-content: center;
                justify-items: center;
                align-items: center;
                // font-size: 14px;
                // font-weight: 500;
                .actWrap{
                    display: flex;
                    align-items: center;
                    span{
                        padding-left: 5px;
                    }
                }
                .added{
                    color: green;
                }
                .add{
                    svg{                    
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    
}

.slug {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
        display: block;
    }
    svg{
        &:hover{
            cursor: pointer;
        }
    }
}

.edit-slug{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    gap: 15px;
    .title{
        font-weight: bolder;
    }
    .edit-field{
        width: 70%;
        input{
            width: 100%;
            height: 35px;
        }
    }
    .btns{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .invalid{
            font-size: medium;
            font-weight: 500;
            color: red;
        }
        .valid {
            font-size: medium;
            font-weight: 500;
            color: green;
        }
        button{
            font-size: 14px;
            font-weight: 600;
            padding: 6px 40px;
            background-color: #0b5ed7;
            &:hover{
                background-color:#05357e;
            }
        }
    }
}
