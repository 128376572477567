.page-notfound {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 7rem;
  }
  p {
    font-weight: bolder;
    color: lightslategrey;
  }
}
