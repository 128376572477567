

.topBorder{
  height: 20px;
  width: 100%;
  background-color: bisque;
}
.appWrapper {
  background: url('./Assets/images/png/background.jpg') center/cover;
  height: 100vh;
  &:has(.page-notfound){
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:not(:has(.loginform, .page-notfound)){
    background-image: none;
  }
}
