.fetchForm{
    margin-top: 30px;
    margin-bottom: 50px;
    .wrapper{
        border: 2px solid gray;
        padding:30px 100px;
        border-radius: 10px;
    }
    .selectDrop{
        .css-1dimb5e-singleValue,.css-1nmdiq5-menu{
            font-size: 13px;
        }
        .label{
            display: block;
            padding: 10px 0px;
            font-size: 14px;
            font-weight: 600
            
        }
        .errorField{
            color: red;
            font-size: 13px;
            font-weight: 500;
        }
    }
    .fetchSub{
        display: flex;
        justify-content: flex-end;
        margin-top: 45px;
        margin-right: 70px;
        button{
            font-size: 14px;
            font-weight: 600;
            padding: 6px 40px;
            background-color: #0b5ed7;
            &:hover{
                background-color:#05357e;
            }
        }
    }
    
    .datePickerDrop{
        display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            .react-datepicker-wrapper{
                input{
                    // padding-top: 10px;
                    padding: 9px;
                    padding-left: 35px;
                    width: -webkit-fill-available;
                    font-size: 13px;
                    border: 1px solid;
                    border-radius: 4px;
                    border-color: hsl(0, 0%, 80%);
                }
                svg{
                    padding: 12px;
                    
                }
            }
    }
}

