.accordianTableList{
    margin-bottom: 30px;
    .accordion-item{
        border: 2px solid;
        border-color: darkgray;

        .accordion-header{
          
            button{
              font-weight: 500;
                
            }

        }
    }
}